import PropTypes from "prop-types";
import React from "react";
import IconBoxData from "../../data/iconBox/icon-box.json";
import IconBox from "../../components/IconBox/IconBox.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";

const ServiceIconBox = ({ classOption }) => {
  return (
    <div className={`section pb-10 ${classOption}`}>
       <br />
       <br />
      <div className="container">
        <SectionTitle
          title="Explore our Services, Find your Solutions"
          subTitle="Discover the Perfect Solutions at NS Marketing for your Business"
        />
       
        <div className="row cursor-pointer items-center lg:px-[110px] inline-block justify-center row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 border-b-slate-300">
          {IconBoxData &&
            IconBoxData.slice(0, 12).map((single, key) => {
              return (
                <div
                  key={key}
                  className="col mb-6 group border-b-gray-400"
                  data-aos="fade-up"
                >
                  <IconBox classOption="box-border" data={single} key={key} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

ServiceIconBox.propTypes = {
  classOption: PropTypes.string,
};
ServiceIconBox.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default ServiceIconBox;
