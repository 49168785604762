import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Footer from "../container/Footer/Footer";
import AskAiComp from "../components/askai";

const AskAi = () => {
  return (
    <React.Fragment>
      <SEO title="Ask AI | NS Marketing" />
      <Header />
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "#424A5D",
        }}
      />
      <AskAiComp />
      <Footer />
    </React.Fragment>
  );
};

export default AskAi;
