import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import IntroThree from "../container/IntroSlider/IntroThree";
import BusinessSetupData from "../data/businessSetup/data.json";
import { useParams } from "react-router-dom";
import BusinessSetupComp from "../components/BusinessSetup";
import ContactFromContainer from "../container/ContactFromContainer/ContactFromContainer";
import Footer from "../container/Footer/Footer";
import BusinessSetupSteps from "../components/BusinessSetupSteps";

const BusinessSetup = () => {
  
  const { service } = useParams();
  const businessSetup = BusinessSetupData.find(
    (businessSetup) => businessSetup.name === service
  );
  const {
    description,
    image,
    title,
    name,
    aboutTitle,
    aboutDescription,
    stepsTitle,
    stepsSubtitle,
    steps,
  } = businessSetup;
  return (
    <div>
      <React.Fragment>
        <SEO title={`${name} | NS Marketing`} />
        <Header />
        <IntroThree image={image} title={title} description={description} />
        <BusinessSetupComp title={aboutTitle} description={aboutDescription} />
        <BusinessSetupSteps
          title={stepsTitle}
          subtitle={stepsSubtitle}
          steps={steps}
        />
        <ContactFromContainer />
        <Footer />
      </React.Fragment>
    </div>
  );
};

export default BusinessSetup;
