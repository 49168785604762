import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FooterLinkItem = ({ data }) => {
  return (
    <div className="footer-widget">
      <h3
        className=""
        style={{
          color: "#fff",
          fontSize: "1.5rem",
          fontWeight: "700",
          marginBottom: "1.5rem",
          whiteSpace: "nowrap",
        }}
      >
        {data.title}
      </h3>
      <div className="footer-widget-content">
        <ul>
          {data.list.map((single, key) => (
            <li key={key}>
              <Link
                to={single.url}
                reloadDocument={single.label == "Contact" ? true : false}
                className="text-white hover:underline"
              >
                {single.text}{" "}
                {single?.badge && (
                  <span className="ft-badge">{single.badge}</span>
                )}{" "}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FooterLinkItem.propTypes = {
  data: PropTypes.object,
};

export default FooterLinkItem;
