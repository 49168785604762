import PropTypes from "prop-types";
import React from "react";
import IconBoxData from "../../data/iconBox/icon-box.json";
import IconBox from "../IconBox/IconBox.jsx";
import SectionTitle from "../SectionTitles/SectionTitle.jsx";
const BusinessSetupSteps = ({ classOption, steps, subtitle, title }) => {
  return (
    <div className={`section section-padding-t90-b100 ${classOption}`}>
      <div className="container">
        <SectionTitle title={title} subTitle={subtitle} />

        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
          {steps &&
            steps.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col mb-6"
                  data-aos="fade-up"
                  style={{
                    padding: "10px",
                  }}
                >
                  <div className={`icon-box text-center ${classOption}`}>
                    <div className="content">
                      <h3 className="title">{single.title}</h3>
                      <div className="desc">
                        <p>{single.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

BusinessSetupSteps.propTypes = {
  classOption: PropTypes.string,
};
BusinessSetupSteps.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default BusinessSetupSteps;
