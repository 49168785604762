import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {

    if (!data.name || !data.email || !data.phone || !data.service || !data.message) {
      setErrorMessage("Please fill in all required fields");
      return;
    }

    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Add static parameter to data
    data.website = "marketing";

    try {
      console.log(data);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setSuccessMessage("Your message has been sent successfully!");
        reset();
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              placeholder="Your Name *"
              name="name"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors?.name && <p>{errors.name?.message}</p>}
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="email"
              placeholder="Email *"
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors?.email && <p>{errors.email?.message}</p>}
          </div>
         
          <div className="col-md-6 col-12 mb-6">
            <input
              type="number"
              placeholder="Phone Number *"
              name="phone"
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "Invalid phone number",
                },
              })}
            />
            {errors?.phone && <p>{errors.phone?.message}</p>}
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              placeholder="Company Name"
              name="companyName"
              {...register("companyName")}
            />
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="text"
              placeholder="Website Link"
              name="websiteLink"
              {...register("websiteLink")}
            />
          </div>
          <div className="col-md-6 col-12 mb-6">
            <select
              placeholder="Service *"
              name="service"
              className=""
              style={{
                backgroundColor: "#F5F5F5",
                paddingInline: "10px",
                padding: "17px",
                borderRadius:"8px",
                color:"#AFAFAF",
                width: "100%",
              }}
              {...register("service", {
                required: "Service is required",
              })}
            >
              <option value={'n/a'} disabled selected>Select service *</option>
              <option value="social-media-marketing">Social Media Marketing</option>
              <option value="social-media-management">Social Media Management</option>
              <option value="seo-campaigns">Search Engine Optimization and Campaigns</option>
              <option value="digital-marketing-strategy">Digital Marketing Strategy and Consultation</option>
              <option value="marketing-automation">Marketing Automation and Content Marketing</option>
              <option value="account-based-marketing">Account Based Marketing</option>
              <option value="company-branding">Company Branding</option>
              <option value="leads-generation">Leads Generation</option>
              <option value="graphic-designing">Graphic Designing</option>
              <option value="video-editing">Video Editing</option>
              <option value="influencer-marketing">Influencer Marketing</option>
              <option value="content-creation">Content Creation</option>

            </select>
            {errors?.service && <p>{errors.service?.message}</p>}
          </div>
          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message"
              {...register("message", {
                required: "Message is required",
              })}
            ></textarea>
            {errors?.message && <p>{errors.message?.message}</p>}
          </div>
          <div className="col-12 text-center mb-6">
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary"
              disabled={loading}
            >
              {loading ? "Sending..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
      {successMessage && (
        <p style={{ textAlign: "center", marginTop: "20px" }}>
          {successMessage}
        </p>
      )}
      {errorMessage && (
        <p
          style={{ textAlign: "center", marginTop: "20px" }}
          className="error-message"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default ContactForm;
