import React, { useState } from 'react';
import './style.css';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import { FaCheckCircle } from 'react-icons/fa';
import {
  FaChevronUp,
  FaChevronDown,
  FaBullhorn,
  FaChartLine,
  FaPenNib,
  FaSearch,
  FaExternalLinkAlt,
  FaGoogle,
  FaFileAlt,
  FaMapMarkerAlt,
  FaCode,
  FaShoppingCart,
  FaPen,
  FaBriefcase,
  FaUsers,
  FaVideo,
  FaCamera,
  FaPuzzlePiece,
  FaFingerprint,
  FaPaintBrush,
  FaInstagram,
  FaLaptop,
  FaGlobe,
  FaMobileAlt,
  FaCogs,
  FaUsersCog,
  FaNetworkWired,
  FaShoppingBag,
  FaBuilding,
  FaUniversity,
  FaChalkboardTeacher,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const iconMap = {
  bullhorn: FaBullhorn,
  chartLine: FaChartLine,
  penNib: FaPenNib,
  search: FaSearch,
  externalLinkAlt: FaExternalLinkAlt,
  google: FaGoogle,
  fileAlt: FaFileAlt,
  mapMarkerAlt: FaMapMarkerAlt,
  code: FaCode,
  shoppingCart: FaShoppingCart,
  pen: FaPen,
  briefcase: FaBriefcase,
  users: FaUsers,
  video: FaVideo,
  camera: FaCamera,
  puzzlePiece: FaPuzzlePiece,
  fingerprint: FaFingerprint,
  paintBrush: FaPaintBrush,
  instagram: FaInstagram,
  laptop: FaLaptop,
  globe: FaGlobe,
  mobileAlt: FaMobileAlt,
  cogs: FaCogs,
  usersCog: FaUsersCog,
  networkWired: FaNetworkWired,
  shoppingBag: FaShoppingBag,
  building: FaBuilding,
  university: FaUniversity,
  chalkboardTeacher: FaChalkboardTeacher,
};

const SubServiceComp = ({
  content,
  image,
  subpoints,
  heading,
  faqs,
  subservices,
}) => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openFaq, setOpenFaq] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const data = {
      ...formData,
      website: 'marketing',
    };

    //   try {
    //     const response = await fetch(
    //       ${process.env.REACT_APP_BASE_URI}/contact,
    //       {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data),
    //       }
    //     );

    //     if (response.ok) {
    //       setSuccessMessage('Your message has been sent successfully!');
    //       setFormData({
    //         name: '',
    //         email: '',
    //         message: '',
    //       });
    //     } else {
    //       throw new Error('Something went wrong, please try again.');
    //     }
    //   } catch (error) {
    //     setErrorMessage(error.message);
    //   } finally {
    //     setLoading(false);
    //   }
  };
  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="myContainer">
      <div className="left">
        <Markdown
          rehypePlugins={[rehypeRaw, rehypeSlug]}
          remarkPlugins={[remarkGfm]}
        >
          {heading}
        </Markdown>
        {/* subpoints table */}
        <div className="tableWrapper">
          <div className="tableLeft">
            <img src={image} />
          </div>
          <div className="tableRight">
            {subpoints.map((subpoint, index) => (
              <div key={index} className="tableRow">
                <FaCheckCircle size={30} color="#3DB5B1" />
                <p>{subpoint}</p>
              </div>
            ))}
          </div>
        </div>
        {/* content  */}
        <Markdown
          rehypePlugins={[rehypeRaw, rehypeSlug]}
          remarkPlugins={[remarkGfm]}
        >
          {content}
        </Markdown>

        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-6">
            Frequently Asked Questions
          </h2>
          <div className="faqWrapper">
            {faqs.map((faq, index) => (
              <div key={index} className="faqItem mb-4 border-b pb-4">
                <div
                  className="faqQuestion flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <p className="text-lg font-semibold">{faq.question}</p>
                  {openFaq === index ? (
                    <FaChevronUp className="text-teal-500" />
                  ) : (
                    <FaChevronDown className="text-teal-500" />
                  )}
                </div>
                {openFaq === index && (
                  <div className="faqAnswer mt-4 text-gray-600">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Subservices Cards */}
          <div className="mt-12">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
              Our Subservices
            </h2>
            <div className="subservicesWrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {subservices.map((subservice, index) => {
                const Icon = iconMap[subservice.icon] || FaBullhorn; // Default icon if not found
                return (
                  <Link
                    key={index}
                    href={subservice.link}
                    className="subserviceCard no-underline group p-6 bg-gray-100 hover:bg-teal-50 transition-colors duration-300 ease-in-out transform text-center"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default anchor behavior
                      const target = document.querySelector(subservice.link);

                      if (target) {
                        const offset = 120; // Adjust this offset for sticky headers, etc.
                        const bodyRect =
                          document.body.getBoundingClientRect().top;
                        const elementRect = target.getBoundingClientRect().top;
                        const elementPosition = elementRect - bodyRect;
                        const offsetPosition = elementPosition - offset;

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <div className="flex flex-col items-center mb-4">
                      <div className="iconWrapper bg-teal-500 p-4 rounded-full mb-4">
                        <Icon className="text-white" size={30} />
                      </div>
                      <h3 className="text-lg font-semibold text-gray-800 group-hover:text-teal-500 transition-colors duration-300">
                        {subservice.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 group-hover:text-gray-900 transition-colors duration-300">
                      {subservice.description}
                    </p>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit}>
        <h3>Inquire Now</h3>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            className="form-control"
            id="message"
            placeholder="Enter Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button
          type="submit"
          className="btn"
          style={{
            backgroundColor: "#3DB5B1",
            color: "white",
          }}
          disabled={loading}
        >
          {loading ? "Sending..." : "Submit"}
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form> */}
    </div>
  );
};

export default SubServiceComp;
