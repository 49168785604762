import React, { useState } from "react";
import { IoIosSend } from "react-icons/io";
import Markdown from "react-markdown";
import { AiOutlineEnter } from "react-icons/ai";
import axios from "axios";
import "./style.css";

const LoadingSkeleton = ({ className, ...props }) => {
  return (
    <div
      style={{
        animation: "pulse 1.5s infinite",
        borderRadius: "8px",
        backgroundColor: "#e0e0e0",
        ...props.style,
      }}
      className={className}
      {...props}
    />
  );
};
const AskAiComp = () => {
  const [prompt, setPrompt] = useState("");
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const gptResponse = async () => {
    setChat((prev) => [...prev, { user: "user", message: prompt }]);
    setPrompt("");
    setLoading(true);
    try {
      const response = await axios.post("http://localhost:4000/gpt", {
        prompt: prompt,
      });
      if (response.data.response.status == 429) {
        setChat((prev) => [
          ...prev,
          {
            user: "gpt",
            message:
              "Sorry, I am currently unavailable. Please try again later.",
          },
        ]);
        setLoading(false);
        return;
      }
      setChat((prev) => [
        ...prev,
        { user: "gpt", message: response.data.response },
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        height: "85vh",
        width: "100%",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div
        style={{
          border: "1px solid #ddd",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      >
        <h5
          style={{
            color: "#333",
            textAlign: "center",
            margin: "0 0 20px 0",
            fontWeight: "bold",
            letterSpacing: "1px",
            padding: "10px 0",
            borderBottom: "2px solid #8F0069",
          }}
        >
          Chat with NS Marketing AI
        </h5>
        <div
          style={{
            flex: 1,
            color: "#333",
            textAlign: "justify",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            paddingRight: "10px",
          }}
        >
          {chat.length > 0 &&
            chat.map((item, index) => (
              <div
                key={index}
                style={{
                  alignSelf: item.user === "user" ? "flex-end" : "flex-start",
                  margin: "8px 0",
                  maxWidth: "70%",
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      item.user === "user" ? "#8F0069" : "#1A1A1A",
                    padding: "12px 20px",
                    borderRadius: "20px",
                    display: "inline-block",
                    color: "#fff",
                    wordWrap: "break-word",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {item.message}
                </div>
              </div>
            ))}
          {/* {loading && (
            <div
              style={{
                alignSelf: "flex-start",
                margin: "8px 0",
                maxWidth: "70%",
              }}
            >
              <LoadingSkeleton
                className="pulse"
                style={{ width: "100%", height: "20px", marginBottom: "10px" }}
              />
              <LoadingSkeleton
                className="pulse"
                style={{ width: "100%", height: "20px", marginBottom: "10px" }}
              />
              <LoadingSkeleton
                className="pulse"
                style={{ width: "100%", height: "20px", marginBottom: "10px" }}
              />
            </div>
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            border: "1px solid #ddd",
            borderRadius: "20px",
            overflow: "hidden",
            alignItems: "center",
            backgroundColor: "#f7f7f7",
            padding: "5px 10px",
          }}
        >
          <input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter a prompt..."
            disabled={loading}
            style={{
              flex: 1,
              padding: "10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              marginRight: "10px",
              backgroundColor: "#fff",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") gptResponse();
            }}
          />
          <AiOutlineEnter
            style={{ fontSize: "24px", color: "#8F0069", marginRight: "10px" }}
          />
          <button
            onClick={gptResponse}
            style={{
              backgroundColor: "#8F0069",
              padding: "10px 15px",
              borderRadius: "20px",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoIosSend style={{ fontSize: "24px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskAiComp;
