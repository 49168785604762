import React, { useState } from "react";
import { Link } from "react-router-dom";
import navLinks from "../../data/navLinks";
import { FaChevronDown } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
  const [hovered, setHovered] = useState(false);

  function scrollWithOffset(el) {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150; // Change this value to the desired offset
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  }

  return (
    <nav className="site-main-menu">
      <ul>
        {navLinks.map((link, index) => (
          <li key={index}>
            <Link
              onMouseEnter={() => link.label === "Services" && setHovered(true)}
              onMouseLeave={() =>
                link.label === "Services" && setHovered(false)
              }
              to={process.env.PUBLIC_URL + link.href}
              reloadDocument={link.label == "Contact Us" ? true : false}
            >
              <span className="menu-text">
                {link.label}
                {link.children && <FaChevronDown className="pl-1.5" />}
              </span>
              {hovered && link.children && (
                <div className="fixed top-20 left-1/2 -translate-x-1/2 bg-white text-black w-[95%] grid grid-cols-6 gap-5 shadow-md rounded-md p-6">
                  {link.children.map((child) => (
                    <Link
                      to={child.href}
                      key={child.label}
                      className="flex flex-col"
                    >
                      <p className="cursor-pointer hover:underline text-lg">
                        {child.label}
                      </p>
                      {child.children && (
                        <div className="flex flex-col gap-2">
                          {child.children.map((sub) => (
                            <HashLink
                              to={sub.href}
                              scroll={(el) => scrollWithOffset(el)}
                              key={sub.label}
                              smooth
                              className="hover:underline text-neutral-500"
                            >
                              <p>{sub.label}</p>
                            </HashLink>
                          ))}
                        </div>
                      )}
                    </Link>
                  ))}
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
