import React from 'react';
import SEO from '../components/SEO';
import Header from '../partials/header/Header';
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import Subservices from '../data/services/data.json';
import { useParams } from 'react-router-dom';
import SubServiceComp from '../components/SubServiceComp/index.js';
import ServiceIconBox from '../container/service/ServiceIconBox';

const SubService = () => {
  const { subService } = useParams();

  const subservice = Subservices.find(
    (subservice) => subservice.name === subService
  );

  if (!subservice) {
    return (
      <React.Fragment>
        <SEO title="404 | NS Marketing" />
        <Header />
        <div className="section section-padding-top section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2>404</h2>
                  <p>
                    The page you are looking for might have been removed had its
                    name changed or is temporarily unavailable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ScrollToTop />
      </React.Fragment>
    );
  }

  const { title, content, heading, image, subpoints, faqs, subservices } =
    subservice;

  return (
    <React.Fragment>
      <SEO
        title={`${
          subService[0].toUpperCase() + subService.slice(1)
        } | NS Marketing`}
      />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-five.jpg"
        title={title}
        content="Home"
        contentTwo="Services"
        contentThree={title}
      />
      <SubServiceComp
        content={content}
        heading={heading}
        subpoints={subpoints}
        image={image}
        faqs={faqs}
        subservices={subservices}
      />
      <ServiceIconBox classOption="bg-color-1" />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default SubService;
